<template>
  <body-layout>
    <div
      class="actions flex "
      slot="actions"
    >
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'system:coefficientPar:delete'"
      >删除</a-button>
      <a-button
        type="default"
        class="mr-3"
        @click="batchCopy"
        icon="copy"
        v-if="selectedRowKeys.length"
        v-has="'system:coefficientPar:edit'"
      >创建副本</a-button>
      <!-- <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length>=2"
        icon="cloud"
        v-has="'system:coefficientPar:edit'"
      >同步</a-button> -->
      <a-button
        type="primary"
        @click="openAddForm"
        v-has="'system:coefficientPar:add'"
        icon="plus"
      >新建</a-button>
    </div>
    <div>
      <a-table
        ref="table"
        style="border-top:1px solid rgb(232,232,232)"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,columnWidth:45 }"
        @change="handleTableChange"
      >
        <span
          slot="type"
          slot-scope="text, record"
        >
          {{ record.cardList[0] | typeFilter }}
        </span>
        <span
          slot="departmentNames"
          slot-scope="text, record"
        >
          <a-tag
            v-for="one in record.departments"
            :key="one.departId"
          >{{ one.departName }}</a-tag>
        </span>

        <span
          slot="positionNames"
          slot-scope="text, record"
        >
          <a-tag
            v-for="one in record.positions"
            :key="one.positionId"
          >{{ one.positionName+'-' }}{{one.type=='VIEW'?'查看':'编辑'}}</a-tag>
        </span>
        <span
          slot="stages"
          slot-scope="text, record"
        >
          <a-tag
            v-for="one in record.stages"
            :key="one.stageId"
          >{{ one.stageName+'-' }}{{one.type=='VIEW'?'查看':'编辑'}}</a-tag>
        </span>
      </a-table>
    </div>
    <product-modal
      :type="type"
      :relationId="relationId"
      :domain="domain"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import productModal from './modules/product/product-modal.vue'
export default {
  mixins: [JeecgListMixin],
  components: {
    productModal,
  },
  data() {
    return {
      filters: {
        type: this.type,
        relationId: this.relationId,
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 50,
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 105,
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 105,
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 280,
        },
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          width: 160,
        },
        {
          title: '适用阶段',
          scopedSlots: { customRender: 'stages' },
          dataIndex: 'stages',
          width: 160,
        },
      ],
      domain:"TASK_DETAIL",
      dataSource: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: {
          path: '/productDetail/page',
        },
        copyBatch: 'productDetail/copy',
        delete: '/fonts/delete',
        deleteBatch: '/productDetail/',
        syncPath: 'productDetail/sync',
      },
    }
  },
  filters: {
    typeFilter(data) {
      const typeMap = {
        DYNAMIC_FORM: `动态表单${
          data.dataList[0].type == 'INPUT'
            ? '-文本录入'
            : data.dataList[0].type == 'SELECT'
            ? '-下拉选项'
            : data.dataList[0].type == 'TAG'
            ? '-标签'
            : ''
        }`,
        INPUT: '单行文本',
        INPUT_NUMBER: '数字输入框',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签',
      }
      return typeMap[data.type]
    },
  },
  props: {
    type: {
      type: String,
      default: () => 'GLOBAL',
    },
    relationId: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .border-bottom {
  border: none;
}
</style>