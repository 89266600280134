var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:coefficientPar:delete",
                      expression: "'system:coefficientPar:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:coefficientPar:edit",
                      expression: "'system:coefficientPar:edit'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "default", icon: "copy" },
                  on: { click: _vm.batchCopy },
                },
                [_vm._v("创建副本")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "system:coefficientPar:add",
                  expression: "'system:coefficientPar:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("a-table", {
            ref: "table",
            staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
            attrs: {
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              scroll: { x: "max-content", y: "calc(100vh - 353px)" },
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 45,
              },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "type",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("typeFilter")(record.cardList[0])) +
                        "\n      "
                    ),
                  ])
                },
              },
              {
                key: "departmentNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.departments, function (one) {
                      return _c("a-tag", { key: one.departId }, [
                        _vm._v(_vm._s(one.departName)),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "positionNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.positions, function (one) {
                      return _c("a-tag", { key: one.positionId }, [
                        _vm._v(
                          _vm._s(one.positionName + "-") +
                            _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                        ),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "stages",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.stages, function (one) {
                      return _c("a-tag", { key: one.stageId }, [
                        _vm._v(
                          _vm._s(one.stageName + "-") +
                            _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                        ),
                      ])
                    }),
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("product-modal", {
        attrs: {
          type: _vm.type,
          relationId: _vm.relationId,
          domain: _vm.domain,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }